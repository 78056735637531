<template>
  <ion-page>
    <ion-content style="--background: var(--ion-color-light)">
      <ion-header>
        <ion-toolbar>
          <ion-title>Previous Consults</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-card class="main-card">
        <ion-card-header>
          <ion-card-title>Previous Consults</ion-card-title>
          <p>Your stored scripts from previous consults.</p>
        </ion-card-header>

        <ion-card-content>
          <DataTable
            :value="entries"
            responsiveLayout="scroll"
            :paginator="true"
            :rows="10"
            paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink "
            :rowsPerPageOptions="[5, 10, 15, 25, 50, 75]"
            rowsPerPageTemplate="Rows per page"
            currentPageReportTemplate="{first}-{last} of {totalRecords}"
          >
            <Column
              field="date"
              header="Consult Date"
              :sortable="true"
            ></Column>
            <Column field="time" header="Time" :sortable="true"></Column>
            <Column field="doctor" header="Doctor" :sortable="true"></Column>
            <Column header="Action">
              <template #body="{data}">
                <!-- Download file link -->
                  <a
                  class="table-links"
                  v-bind:href="data.script"
                  target="_blank"
                  ><img src="/assets/icon/download.svg" width="14"
                /></a>
                <!-- API call to email script to user -->
                <a class="table-links" href="" @click.prevent="sendScript(data.consult_id)"
                  ><img src="/assets/icon/email.svg" width="14"
                /></a>
              </template>
            </Column>
          </DataTable>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
} from "@ionic/vue";

export default {
  name: "PreviousConsults",
  components: {
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonPage,
    IonContent,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    entries() {
      return this.$store.state.consults.consults;
    },
  },
  ionViewDidEnter() {
    this.$store.dispatch("PreviousConsults", this.user.id).then();
  },
  methods: {
    sendScript(id){
      this.$store.dispatch("SendScript", id).then();
    }
  }
};
</script>

<style scoped>
ion-card-title {
  color: var(--ion-color-primary);
}

.main-card {
  margin-top: 12px;
}

p {
  font-size: 16px;
  line-height: 24px;
}

.p-paginator-current{
  font-size: 10px;
}

.pi-chevron-down{
  font-size: 7px;
}

.p-dropdown-trigger{
  width: 10px;
}

.p-dropdown {
  width: 40px;
}
</style>